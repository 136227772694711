/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@include mat.core();

$theme-primary:  mat.define-palette(mat.$light-blue-palette, 500);
$theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$theme-warn: mat.define-palette(mat.$red-palette);
$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);

@include mat.all-component-themes($theme);

// used for navigation bar
.fill-remaining-space {
  flex: 1 1 auto;
}

figure img{
  max-width: 100%;
 }
 

/* fixes mat-select bug, when displayed in a Bootstrap modal */
.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

.tbl-row {
  display: flex;
}

.tbl-col {
  flex: 1 1;
  height: 100%;
  flex-basis: 0%;
  overflow: hidden;
}

.matTooltip{
  white-space: pre-line;
}

.mat-tooltip{
  white-space: pre-line !important;
}


.matTooltip {
  word-break: break-all;
}

.tbl-col p {
  margin-top: 0.5rem;
  margin-left: 0.3rem;
  font: 400 12px/20px Roboto,"Helvetica Neue",sans-serif;
  white-space: nowrap;
}

.tbl-col .tbl-row .tbl-col p {
  margin-top: 0.3rem;
  margin-left: 0.30rem;
  font: 400 10px/10px Roboto,"Helvetica Neue",sans-serif;
  white-space: nowrap;
}

p.smallest {
  margin-top: 0.3rem;
  margin-left: 0.3rem;
  font: 400 10px/10px Roboto,"Helvetica Neue",sans-serif;
  white-space: nowrap;
}

.tbl-header {
  border-top: #ddd  1px solid;
  border-left: #ddd  1px solid;
  // border-right: #ddd 1px solid;
  border-bottom: #ddd 1px solid  ;

  height: 100%;
}

.tbl-header-invisible {
  border-top: #fff 1px solid;
  border-left: #fff 1px solid;
  border-right: #fff 1px solid;
}

.tbl-row .br {
  border-right: #9E9E9E 1px solid;
}

.assemblyTab .mat-tab-label  {
  min-width: 50% !important;
  border-top: #9E9E9E 1px solid;
  border-right: #9E9E9E 1px solid;
  border-left: #9E9E9E 1px solid;
}

.assemblyTab .mat-tab-label-active {
  background-color: #03a9f4;
  color: #fff;
}

.threadCompoundTab .mat-tab-label  {
  min-width: 70% !important;
}
.threadCompoundTab .mat-tab-list  {
  width: 100% !important;
}

.threadCompoundTab .mat-tab-label-active {
  color: #03a9f4;
}

.threadCompoundTab .mat-tab-header {
  border: #ddd 1px solid;
}

//tdsTab

.tdsTab .mat-tab-label  {
  min-width: 60% !important;
}
.tdsTab .mat-tab-list  {
  width: 100% !important;
}

.tdsTab .mat-tab-label-active {
  color: #03a9f4;
}

.tdsTab .mat-tab-header {
  border: #ddd 1px solid;
  // margin-left: 60px;
}

.tsli-sidenav.mat-expansion-panel-spacing {
  margin: 0;
}

.tsli-sidenav.mat-expansion-panel {
  box-shadow: none;
  background: none;
}

//ckeditor styles for image width and alignement
.image-style-align-right {
  float: right;
  margin-left: 50px;;
}

.image-style-align-left {
  float: left;
  margin-right: 50px;
}

.image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}

.image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

.image.image_resized img {
  width: 100%;
  max-height: 1200px !important;
}

.text-tiny {
  font-size: .7em;
}

.text-small {
  font-size: .85em;
}

.text-big {
  font-size: 1.4em;
}

.text-huge {
  font-size: 1.8em;
}


/* ckeditor5-highlight/theme/highlight.css */
.marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}

.page-num {
  color: #000000;
  font-family: Roboto;
  font-size: 15px;
  font-style: italic;
  // font-weight: bold;
  line-height: 32px;
}


@page {
  size: 1190px 1684px;
  margin: 10px;
  padding-left: 0px;
  padding-right: 0px;
  page-break-inside: avoid;
  counter-increment: page
}

#pageNumber { content: counter(page) }



@media print{

  body {
    counter-reset: page;
  }
  .page-num-value:before {
    counter-increment: page;
    content: "Page "counter(page);
  }

  .overwith-table td, .overwith-table th {
      padding: unset !important;
  }

  img {
    max-height: 1300px !important;
    max-width: 1100px !important;
  }


  .page-break-table {
    page-break-before: always !important 
  }

  .table table tr {
    page-break-after: auto !important;
    
  }

  .table-tr-break{
    page-break-before: always !important;

  }
  figure.table{
    max-width: 100% !important;
  }
  // .table table td, .table table th{
  //   page-break-inside: auto !important;
  //   page-break-after: auto !important;
  //   word-wrap: break-word;
    
  // }


figure.image {
  break-inside: avoid !important;
}

  // table { page-break-inside:auto !important}
  //   thead tr { display:table-header-group !important; }

  div.page-break{
    display: block !important;
    page-break-after: always !important;
  }

  figure table div.page-break{
     display: none !important;
   }

}

div.page-break{
  display: none;
}

.pre-page-break-para{
margin-bottom: 0 !important;
}



body {
  background: transparent;
}

span .marker-yellow, span .marker-green, span .marker-pink, span .marker-blue {
  color: inherit !important;
}

.table table th, .table table td, .table table{
  border: solid black 2px;
}

.no-border-bottom{
  border-bottom: none !important;
}

.no-border-top{
  border-top: none !important;
}

.no-border-right{
  border-right: none !important;
}

.no-border-left{
  border-left: none !important;
}
figure.table{
  width: unset !important;
}


.carousel-control-next, .carousel-control-prev {
  width: 3% !important;
}